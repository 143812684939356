import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

export default function AffliationPage() {
  const [searchParams] = useSearchParams();

  useEffect(() => {
    window.location.href = handleRedirect();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleRedirect = () => {
    let url = "";

    switch (searchParams?.get("r")) {
      case "c":
        {
          const orgUniqueText = searchParams?.get("o");
          const campaignUniqueText = searchParams?.get("cp");
          const contestUniqueText = searchParams?.get("cn");
          if (contestUniqueText) {
            url = `https://contest.goviral.world/${orgUniqueText}/${campaignUniqueText}/${contestUniqueText}.html`;
          } else {
            url = `https://contest.goviral.world/${orgUniqueText}/${campaignUniqueText}.html`;
          }
        }
        break;
      case "u":
        url = searchParams?.get("u");
        break;
      default:
        url = "https://www.goviral.world/";
        break;
    }
    return url;
  };

  return <div>Loading</div>;
}
